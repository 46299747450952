import React from "react";
import Layout from "../layouts/zh";
import SEO from "../components/seo";
import { RiversideGolf } from "../components/TWComponents/PayByPoints";
import langData from "../data/static-pages/pages/riverside-golf/zh";
const RiversideGolfPage = (props) => {
  const [openMobileNav, setOpenMobileNav] = React.useState(false);
  const [openWidget, setOpenWidget] = React.useState(false);
  const openWidgetAction = () => {
    setOpenWidget(!openWidget);
  };
  return (
    <Layout
      location={props.location}
      showBookNowButton={false}
      mobileWidgetStatus={openWidget}
      setMobileWidgetStatus={setOpenWidget}>
      {" "}
      <SEO
        title="Riverside Golf Club"
        keyword="Riverside Golf Club"
        decription="Riverside Golf Club"
      />{" "}
      <RiversideGolf
        openWidgetAction={openWidgetAction}
        langData={langData}
        locale="zh"
        tnclink="https://rewards.ayana.com/terms-and-conditions-8"
      />{" "}
    </Layout>
  );
};
export default RiversideGolfPage;
