module.exports = {
  intro: {
    title1: "Introducing Pay with Points",
    title2: "by AYANA Rewards",
    subtitle:
      "Turn your AYANA Rewards Points into Unforgettable Experiences at AYANA Hotels",
    cta: "CHECK RATES",
  },
  title: "在河滨高尔夫俱乐部获得阿雅娜奖励积分。",
  pros: {
    first: {
      title: "在每一轮中赚取积分和解锁奖励",
      description:
        "自2024年5月起，在河滨高尔夫俱乐部每消费15,000印尼卢比，可获得1个阿雅娜奖励积分",
    },
    second: {
      title: "解锁阿雅娜奖励的专属福利",
      description:
        "作为阿雅娜奖励会员，您的积分将为您带来令人激动的福利，例如开球时间折扣、酒店客房升级、提前入住、延迟退房等等。",
      tierLevel: "*基于你的等级。",
    },
  },
  preferred_dest: {
    title: "兑换您的积分并选择您的阿雅娜度假",
    subtitle:
      "在印尼各地的阿雅娜酒店等待着您难忘的体验!兑换您的阿雅娜奖励积分，享受奢华的逃离以及度假天堂体验。",
    bali: "巴厘岛",
    jakarta: "雅加达",
    komodo: "科莫多",
    DXKR: "住在加拉黄凤凰木酒店",
    JKTHB: "住在阿雅娜雅加达中央广场酒店",
    SEGARA: "AYANA Segara Bali",
    hotelStay: "酒店住宿",
    giftVoucher: "礼券",
    krisFlyerMiles: "KrisFlyer英里",
    diningVoucher: "餐饮券",
  },
  how_it_works: {
    title: "解锁专属福利和奖励:河滨高尔夫俱乐部的阿雅娜奖励",
    subtitle: "Here's how it works:",
    first: "通过会员专属福利提升您的体验。现在加入!",
    second: "在购买年度会员资格后立即提升到阿雅娜的奖励黄金级别。(条件)",
    third: "购买河滨长期会员资格后，立即提升至阿雅娜奖励白金级别(适用于T&C)",
    fourth: "金卡及白金卡会员可根据符合条件的消费获得积分(适用条款及细则)",
  },
  find_hotels: {
    title: "成为阿雅娜奖励会员，享受专属特权。",
    cta: "现在就注册吧!",
  },
  faq: {
    title: "常见问题与解答",
    first: {
      question: "我怎样才能预定河滨高尔夫俱乐部的开球时间?",
      answer: "您可以通过河滨高尔夫俱乐部网站 在线预订开球时间。",
      link: "https://riverside-gc.com/",
    },
    second: {
      question: "当我购买年度或长期河滨会员资格时，我能获得阿雅娜奖励积分吗?",
      answer:
        "不，您不会通过购买年度或长期会员资格直接获得阿雅娜奖励积分。然而，这些会员资格可以立即升级您的阿雅娜奖励纽带。",
    },
    third: {
      question: "我的积分需要多长时间才能到账?",
      answer:
        "通常最多需要7个工作日，您的阿雅娜奖励积分才会发放到您的账户。如果您对7个工作日后的积分缺失有任何疑问，可以直接通过info@ayanarewards.com与阿雅娜奖励联系。",
    },
  },
  tnc: "Terms & Conditions",
};
